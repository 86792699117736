//
import React from "react"

//
import Layout from '../layout/index';
import Website from 'routes/site-internet/index.js';

/*
 *
 */
export default (props) => (
	<Layout {...props}>
		<Website />
	</Layout>
);